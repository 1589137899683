<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ChiTieuMoiTruong" data-bs-toggle="tab"
                                    href="#ChiTieuMoiTruong" role="tab" aria-controls="ChiTieuMoiTruong"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Số liệu môi trường hàng
                                        ngày</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ChiTieuMoiTruong" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Bắt đầu</label>
                                        <input
                                            [ngModel]="ChiTieuMoiTruongService.BaseParameter.BatDau | date:'yyyy-MM-dd'"
                                            style="text-align: right;" (ngModelChange)="DateBatDau($event)"
                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                            autocomplete="off">
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Kết thúc</label>
                                        <input
                                            [ngModel]="ChiTieuMoiTruongService.BaseParameter.KetThuc | date:'yyyy-MM-dd'"
                                            style="text-align: right;" (ngModelChange)="DateKetThuc($event)"
                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                            autocomplete="off">
                                    </div>
                                    <div class="col-lg-5 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="ChiTieuMoiTruongService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ChiTieuMoiTruongSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ChiTieuMoiTruongSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span
                                                *ngIf="ChiTieuMoiTruongService.List">({{ChiTieuMoiTruongService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ChiTieuMoiTruongSort="matSort"
                                                    [dataSource]="ChiTieuMoiTruongService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="ChiTieuMoiTruongPaginator">
                                                                {{ChiTieuMoiTruongPaginator.pageSize *
                                                                ChiTieuMoiTruongPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày ghi
                                                            nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                                style="text-align: right;"
                                                                (ngModelChange)="DateNgayGhiNhan(element,$event)"
                                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                                class="form-control" autocomplete="off">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ToChucID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Trạm quan
                                                            trắc (bắt buộc)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select" name="element.ToChucID"
                                                                [(ngModel)]="element.ToChucID">
                                                                <option *ngFor="let item of ToChucService.List;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}} [{{item.ID}}]</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucChiTieuMoiTruongID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ tiêu môi trường (bắt buộc)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucChiTieuMoiTruongID"
                                                                [(ngModel)]="element.DanhMucChiTieuMoiTruongID">
                                                                <option
                                                                    *ngFor="let item of DanhMucChiTieuMoiTruongService.List;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}} [{{item.ID}}]</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucChiTieuMoiTruongName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ tiêu
                                                            (Thêm nếu thiếu)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Chỉ tiêu (Thêm nếu thiếu)"
                                                                name="DanhMucChiTieuMoiTruongName{{element.ID}}"
                                                                [(ngModel)]="element.DanhMucChiTieuMoiTruongName">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLieu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Số liệu (bắt buộc)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                placeholder="Số liệu (bắt buộc)" name="SoLieu{{element.ID}}"
                                                                [(ngModel)]="element.SoLieu">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Display">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị
                                                            tính
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Đơn vị tính" name="Display{{element.ID}}"
                                                                [(ngModel)]="element.Display">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Description">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mô tả (nếu có)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Mô tả (nếu có)"
                                                                name="Description{{element.ID}}"
                                                                [(ngModel)]="element.Description">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ChiTieuMoiTruongSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ChiTieuMoiTruongDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ChiTieuMoiTruongService.DisplayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ChiTieuMoiTruongService.DisplayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ChiTieuMoiTruongPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ChiTieuMoiTruongSort="matSort"
                                                    [dataSource]="ChiTieuMoiTruongService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">

                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ChiTieuMoiTruongService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ChiTieuMoiTruongService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ChiTieuMoiTruongPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ChiTieuMoiTruongService.IsShowLoading"></app-loading>